import React, { Fragment} from 'react';
import { Link } from "gatsby"
import { compose } from 'recompose';
import Layout from "../components/layout"
import { css } from "@emotion/core";
import Helmet from "react-helmet";
import SEO from "../components/seo"
import Navlinks from '../components/nav';
import Icon from '@material-ui/core/Icon';
import Messages from '../components/Messages';
import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from '../components/Session';

const condition = authUser => !!authUser;

const LandingPageBase = () => (
  <Fragment>
    <AuthUserContext.Consumer>
      {authUser => (
        <React.Fragment>
          <SEO title="Compass Minerals SaltSmart" />
          <Helmet defer={true}>
            <body className="notifications" />
          </Helmet>
          <header className="mainHead flexContainer faCenter fjCenter notifactionsHead">      
            <div className="fCol12 menuCtrl">
              <Link className="headerTitle" to={`/#`}>
                <span className="arrowbk"><Icon>arrow_back</Icon></span> Home
              </Link>              
              <Navlinks />
            </div>
          </header>
          <div id="tabsContainer" className="tabsContainer messages" css={css`margin-top:5vh;overflow-x:visible;`}>           
            <Messages />            
        </div>
        </React.Fragment>
        )}
    </AuthUserContext.Consumer> 
  </Fragment>
);

const LandingPage = compose(
  withEmailVerification,
  withAuthorization(condition),
)(LandingPageBase);

export default () => (
  <Layout>
    <LandingPage />
  </Layout>
);