import React from 'react';

import MessageItem from './MessageItem';

const MessageList = ({
  authUser,
  messages,
  onEditMessage,
  onRemoveMessage,
  onChangeArchiveTrue,
  sortByRead
}) => (
  <ul className="fCol12 messageList activeP">
    {sortByRead === true &&
      <React.Fragment>
      {messages.sort((a, b) => b.createdAt - a.createdAt).map(message => (
        <MessageItem
          authUser={authUser}
          key={message.uid}
          message={message}
          onEditMessage={onEditMessage}
          onRemoveMessage={onRemoveMessage}
          onChangeArchiveTrue={onChangeArchiveTrue}
        />
      ))}
      </React.Fragment>
    }
  </ul>
);

export default MessageList;